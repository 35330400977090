/*
 |-----------------------------------------------------------------------------
 | components/atoms/Honeypot/Honeypot.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

const Honeypot = () => {
	return (
		<label className={`hidden`} htmlFor="honeypot">
			<span>Leave blank</span>
			<input id="honeypot" name="honeypot" type="text" />
		</label>
	);
};

export default Honeypot;
