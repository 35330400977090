/*
 |-----------------------------------------------------------------------------
 | components/molecules/Form/Form.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import * as Sentry from '@sentry/nextjs';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { get, map } from 'lodash';
import dynamic from 'next/dynamic';
// import { ray } from 'node-ray/web';
import { useRouter } from 'next/router';

import Hidden from '@/atoms/Hidden';
import Honeypot from '@/atoms/Honeypot';

import * as IForm from './types';

const Button = dynamic(() => import('@/atoms/Button'));
const Checkbox = dynamic(() => import('@/atoms/Checkbox'));
const Input = dynamic(() => import('@/atoms/Input'));
const Radio = dynamic(() => import('@/atoms/Radio'));
const RichText = dynamic(() => import('@/molecules/RichText'));
const Select = dynamic(() => import('@/atoms/Select'));
const Textarea = dynamic(() => import('@/atoms/Textarea'));

const Form: FC<IForm.IProps> = ({
	action,
	controls,
	tinaField,
	title,
	utilities,
}) => {
	// ray('Debug molecule Form:', {
	// 	action: action,
	// 	controls: controls,
	// 	title: title
	// 	utilities: utilities,
	// }).blue();

	const router = useRouter();

	const {
		control: rhfControl,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({
		mode: 'onBlur',
	});

	const encode = (data) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent(data[key])
			)
			.join('&');
	};

	const onSubmit: SubmitHandler<any> = (data, e) => {
		e.preventDefault();

		fetch(action, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: encode({
				'form-name': title,
				...data
			}),
		})
			.then(async response => {
				await router.push(action);
				reset();
			})
			.catch(err => {
				Sentry.captureException(err);
			});
	};

	let buttonVariant, controlVariant, prose, spacing, spacingI, template;
	switch (title) {
		case 'Enquiries':
			buttonVariant = 'primary';
			controlVariant = 'underline';
			prose = 'col-span-2 prose-sm prose-brand-1';
			spacing = '';
			spacingI = ['mt-8 custom:mt-4', 'mt-2'];
			template = `
				gap-x-16
				grid
				grid-cols-2
				custom:block
			`;
			break;
		case 'Referrals':
			buttonVariant = 'primary';
			controlVariant = 'underline';
			prose = 'col-span-2 prose-4xl prose-brand-1 sm:text-3xl';
			spacing = 'space-y-8';
			template = 'gap-x-16 grid grid-cols-2';
			break;
		case 'Subscriptions':
			buttonVariant = 'tertiary';
			controlVariant = 'underline';
			prose = 'prose-xl text-neutral-200';
			spacing = 'space-y-4';
			template = '';
			break;
	}

	return (
		<form
			className={`
				${spacing}
				${template}
				${utilities ? utilities : ''}
			`}
			acceptCharset="UTF-8"
			action={action}
			data-netlify="true"
			data-netlify-honeypot="honeypot"
			method="post"
			name={title}
			noValidate
			onSubmit={handleSubmit(onSubmit)}
		>
			<Hidden
				id="formName"
				isOptional={true}
				name="form-name"
				value={title}
			/>

			<Honeypot />

			{map(controls, (control, index) => {
				switch (get(control, '__typename')) {
					case 'FormControlsButton':
						return (
							<Button
								key={index + get(control, '__typename')}
								label={get(control, 'label')}
								type="submit"
								utilities={`
									justify-self-start
									${spacingI[0]}
									${title === 'Referrals' ? 'col-span-2' : ''}
								`}
								variant={buttonVariant}
							/>
						);
					case 'FormControlsCheckbox':
						return (
							<Controller
								key={index + get(control, '__typename')}
								control={rhfControl}
								name={get(control, 'name')}
								render={({
									field: { name, onBlur, onChange },
								}) => (
									<Checkbox
										hideLegend={get(control, 'hideLegend')}
										isInvalid={errors[get(control, 'name')]}
										isOptional={get(control, 'isOptional')}
										label={get(control, 'label')}
										name={name}
										// name={get(control, 'name')}
										onBlur={onBlur}
										onChange={onChange}
										options={get(control, 'options')}
										utilities={`col-span-2 ${spacingI[1]}`}
										variant={controlVariant}
									/>
								)}
								rules={{
									required: {
										message: `${get(
											control,
											'name'
										)} is required`,
										value: !get(control, 'isOptional'),
									},
								}}
							/>
						);
					case 'FormControlsInput':
						return (
							<Controller
								key={index + get(control, '__typename')}
								control={rhfControl}
								name={get(control, 'name')}
								render={({
									field: { name, onBlur, onChange },
								}) => (
									<Input
										id={get(control, 'id')}
										isInvalid={errors[get(control, 'name')]}
										isOptional={get(control, 'isOptional')}
										label={get(control, 'label')}
										name={name}
										// name={get(control, 'name')}
										onBlur={onBlur}
										onChange={onChange}
										placeholder={get(
											control,
											'placeholder'
										)}
										type={get(control, 'type')}
										utilities={spacingI[0]}
										variant={controlVariant}
									/>
								)}
								rules={{
									...(get(control, 'type') === 'email'
										? {
												pattern: {
													message: `${get(
														control,
														'name'
													)} is invalid`,
													value: /^\S+@\S+$/i,
												},
										  }
										: {}),
									required: {
										message: `${get(
											control,
											'name'
										)} is required`,
										value: !get(control, 'isOptional'),
									},
								}}
							/>
						);
					case 'FormControlsRadio':
						return (
							<Controller
								key={index + get(control, '__typename')}
								control={rhfControl}
								name={get(control, 'name')}
								render={({
									field: { name, onBlur, onChange },
								}) => (
									<Radio
										hideLegend={get(control, 'hideLegend')}
										isInvalid={errors[get(control, 'name')]}
										isOptional={get(control, 'isOptional')}
										label={get(control, 'label')}
										name={name}
										// name={get(control, 'name')}
										onBlur={onBlur}
										onChange={onChange}
										options={get(control, 'options')}
										utilities={`col-span-2`}
										variant={controlVariant}
									/>
								)}
								rules={{
									required: {
										message: `${get(
											control,
											'name'
										)} is required`,
										value: !get(control, 'isOptional'),
									},
								}}
							/>
						);
					case 'FormControlsSelect':
						return (
							<Controller
								key={index + get(control, '__typename')}
								control={rhfControl}
								name={get(control, 'name')}
								render={({
									field: { name, onBlur, onChange },
								}) => (
									<Select
										key={index + get(control, '__typename')}
										id={get(control, 'id')}
										isInvalid={errors[get(control, 'name')]}
										isOptional={get(control, 'isOptional')}
										label={get(control, 'label')}
										name={name}
										// name={get(control, 'name')}
										onBlur={onBlur}
										onChange={onChange}
										options={get(control, 'options')}
										placeholder={get(
											control,
											'placeholder'
										)}
										utilities={spacingI[0]}
										variant={controlVariant}
									/>
								)}
								rules={{
									required: {
										message: `${get(
											control,
											'name'
										)} is required`,
										value: !get(control, 'isOptional'),
									},
								}}
							/>
						);
					case 'FormControlsText':
						return (
							<RichText
								key={index + get(control, '__typename')}
								content={get(control, 'content')}
								isFullWidth
								tinaField={`${tinaField}.controls`}
								utilities={`${prose} ${spacingI[1]}`}
							/>
						);
					case 'FormControlsTextarea':
						return (
							<Controller
								key={index + get(control, '__typename')}
								control={rhfControl}
								name={get(control, 'name')}
								render={({
									field: { name, onBlur, onChange },
								}) => (
									<Textarea
										key={index + get(control, '__typename')}
										hideLabel={get(control, 'label')}
										id={get(control, 'id')}
										isInvalid={errors[get(control, 'name')]}
										isOptional={get(control, 'isOptional')}
										label={get(control, 'label')}
										name={name}
										// name={get(control, 'name')}
										onBlur={onBlur}
										onChange={onChange}
										placeholder={get(
											control,
											'placeholder'
										)}
										rows={get(control, 'rows')}
										utilities={`col-span-2 ${spacingI[0]}`}
										variant={controlVariant}
									/>
								)}
								rules={{
									required: {
										message: `${get(
											control,
											'name'
										)} is required`,
										value: !get(control, 'isOptional'),
									},
								}}
							/>
						);
				}
			})}
		</form>
	);
};

export default Form;
